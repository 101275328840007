<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-5">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="https://kmcorporate.com/wp-content/uploads/2021/06/Easy-S-tre-quarti-rev.2-210609-DEF-1024x696.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-7">
                <div class="mt-4">
                  <h1
                    style="color: black; font-weight: 800"
                    class="font-size-20 mb-3"
                  >
                    MAIN FEATURES
                  </h1>
                  <p style="font-size: 16px; color: black; font-weight: 400">
                    Parallel and crossing wires as well as loops or one-side
                    wire terminations are producible – Min wire length = 150 mm
                    (Loops), 55 mm (Free wire) – Simultaneous processing
                    connectors with different number of ways, coding versions
                    etc. – Max Production configuration: 44 contact positions
                    for Rast 2,5 24 contact positions for Rast 5 – Connector
                    loading system without machine stop – Terminating Unit with
                    fine adjustment termination height – Test device for the
                    correct wire position in the connector – Continuity Test
                    (optional) – Coding station (optional) – Test device of the
                    correct key cutting (optional) – Selective destroying unit
                    of bad harness (optional) – User friendly HMI based on
                    Windows O.S. – Internet connection for remote assistance –
                    Conveyor guide for good harnesses – Expulsion of bad
                    harnesses – CE conformity
                  </p>
                </div>
                <br />
                <div class="mt-4">
                  <h1
                    style="color: black; font-weight: 800"
                    class="font-size-20 mb-3"
                  >
                    CRIMP MODULE (Option)
                  </h1>
                  <p style="font-size: 16px; color: black; font-weight: 400">
                    Max 3 Crimping units, with CFA – Wire Feeding up to 6
                    different wires, length freely programmable – Min. wire
                    length = 60 mm (Free / Crimped / Stripped wires) – Max. wire
                    length = 1500 mm (Free / Crimped / Stripped wires) – Carrier
                    strip chopper (optional) – Paper take-up (optional) – Sleeve
                    Insertion Unit (optional) – Seal Station Unit (optional) –
                    Dimensions: 1300 x 2200 x h 2800 mm (safety shield open) –
                    Weight: approx. 1000 kg
                  </p>
                </div>
                <br />
             
                                        
    
                <p style="font-size: 14px; color: black; font-weight: 400">
                  Data and measurements are indicative and subject to change
                  without notice. Some particular types of cables may not be
                  machined even though they fall within the range of sections
                  indicated. KM Corporate will still be happy to carry out tests
                  and supply wire-worked samples
                </p>
              </div>
            </div>
            <!-- end row -->
            <br />
            <br />
            <br />
            <div class="row">
            <div class="col-5"></div>
            <div class="col-7">
            <button @click="file()" class="btn btn-primary" style="width:100%"><i class="fa fa-download"></i> Download The Technical Sheet</button>
            </div>
            </div>
            <br><br><br>
            <div class="row">
              <h1
                style="color: black; font-weight: 800; margin-left: 40px"
                class="font-size-20 mb-3"
              >
              Gallery
            
              </h1>
              
            </div>
             <br />

            <div class="row" style="margin-left:50px;">
             
              <vue-picture-swipe :items="items"></vue-picture-swipe>
            </div>
            <!-- end row -->
          
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
   
    Header,
    Footer,

    
  },
  data(){
           return {
        items: [{
          src: 'https://kmcorporate.com/wp-content/uploads/2021/06/Easy-S-frontale-rev.2-210608-DEF-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/06/Easy-S-frontale-rev.2-210608-DEF-150x150.jpg',
          w: 1200,
          h: 900,
          alt: 'some numbers on a grey background' // optional alt attribute for thumbnail image
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/Easy-S-KM-024-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/Easy-S-KM-024-150x150.jpg',
          w: 1200,
          h: 900
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/Easy-S-KM-028-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/Easy-S-KM-028-150x150.jpg',
          w: 1200,
          h: 900
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/Easy-S-KM-017-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/Easy-S-KM-017-150x150.jpg',
          w: 1200,
          h: 900
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/07/Easy-S-KM-012-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/Easy-S-KM-012-150x150.jpg',
          w: 1200,
          h: 900
        },
        {
          src: 'https://kmcorporate.com/wp-content/uploads/2021/05/Easy-S_Footprint-scaled-e1622099767193-150x150.jpg',
          thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/05/Easy-S_Footprint-scaled-e1622099767193-150x150.jpg',
          w: 1200,
          h: 900
        }
      ]};

  },

methods: {
  file(){
    window.location.href = "https://drive.google.com/file/d/1OBJhccyXPXi_JYUspiPQ3F5A4AsweZTR/view?usp=sharing";
    
  }
}
};
</script>